

// Plan radio component.
// ---------------------
.plan-radios{
  display: flex;
}
.plan-radio {
  display: flex;
  flex-direction: column;
  width: 215px;
  min-height: 277px;
  border-radius: 5px;
  padding: 15px;
  border: 1px solid #dcdcdc;
  background: #fcfdff;
  text-decoration: none;
  color: #000;
  cursor: pointer;

  &.is-selected {
    border: 1px solid #0069ff;
    background-color: #fcfdff;
  }
  &:not(:first-child) {
    margin-left: 20px;
  }
  &__header {
    display: flex;
    justify-content: flex-start;
  }
  &__name {
    background: #3657ff;
    border-radius: 3px;
    padding: 2px 10px;
    font-size: 13px;
    color: #fff;
    margin-bottom: 18px;
    height: 21px;
    text-transform: uppercase;
  }
  &__description {
    font-size: 14px;
    font-weight: 400;

    ul {
      list-style: none;

      li{
        position: relative;
        padding-left: 12px;
        margin-bottom: 9px;

        &:before{
          content: '-';
          position: absolute;
          left: 0;
          opacity: 0.6;
        }
      }
    }
  }
  &__price {
    margin-top: auto;
    font-size: 15px;
    padding-top: 10px;
  }
  &__amount {
    font-weight: 600;
  }
  &__period {
    font-weight: 400;
    color: #2f3863;

    &::before {
      content: '/';
      display: inline-block;
      margin: 0 2px;
    }
  }
}