

// Plan period radio component.
// ---------------------
.period-radios{
  display: flex;
}
.period-radio{
  display: inline-flex;
  background-color: #fcfdff;
  justify-content: space-between;
  align-items: center;
  width: 240px;
  height: 36px;
  border-radius: 5px;
  padding: 8px 10px;
  color: #000;
  border: 1px solid #dcdcdc;
  cursor: pointer;
  text-decoration: none;

  &.is-selected {
    border: 1px solid #0069ff;
    background-color: #fcfdff;
  }
  &:not(:first-child) {
    margin-left: 20px;
  }
  &__amount{
    font-weight: 600;
  }
  &__period{
    color: #2f3863;
    font-size: 14px;
    font-weight: 500;

    &::before {
      content: '/';
      display: inline-block;
      margin: 0 2px;
    }
  }
}
