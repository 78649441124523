


.dashboard__insider--view-form{
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 90px;

  .view-form--name-section{
    margin-left: -25px;
    margin-right: -25px;
  }
}
.view-form{

  &--name-section{
    padding: 35px 28px;
    background: #fbfafa;
    margin-bottom: 22px;

    .bp4-form-group.bp4-inline{
      margin-bottom: 0;
    }
  }

  &--logic-expression-section{
    padding: 30px 25px;
    margin: 1rem -25px 1.5rem;
    background: #fbfafa;

    .#{$ns}-form-group{
      margin-bottom: 0;
    }
  }

  .condition-number{
    color: #888;
  }

  .#{$ns}-form-group.#{$ns}-inline{

    .#{$ns}-label{
      width: 200px;
    }

    .#{$ns}-form-content{
      width: 100%;
    }
  }

  .new-conditional-btn{
 
  }

  .dragable-columns{

    &__items{
      border: 1px solid #e1e1e1;
      padding: 10px 8px;
      height: 300px;
      overflow: auto;
    }

    &__title{
      color: #888;
      font-weight: 400;
      font-size: 15px;
    }
  }

  .dragable-columns{
    &__column{

      .bp4-input-group .bp4-input{
        border-radius: 3px 3px 0 0;
      }

    }
    

    &__arrows{
      text-align: center;
      margin-top: 140px;
    }
  }

  .dragable-resource-columns{
    
  }

  &__role-conditional{
    margin-top: 1rem;

    .bp4-form-group{
      margin-bottom: 0;
    }
  }

  &__role-conditions-actions{
    margin-top: 10px;
  }
}
