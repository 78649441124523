.ScrollbarsCustom {
  position: 'relative';
  width: 100%;
  height: 100%;
}
.ScrollbarsCustom-Wrapper {
  position: 'absolute';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.ScrollbarsCustom-Content {
  box-sizing: 'border-box';
}

.ScrollbarsCustom-Track {
  &.ScrollbarsCustom-TrackY,
  &.ScrollbarsCustom-TrackX {
    position: absolute;
    overflow: hidden;
    border-radius: 4px;
    user-select: none;
  }
  &.ScrollbarsCustom-TrackX {
    height: 10px;
    width: calc(100% - 10px);
    bottom: 0;
    left: 5px;
  }
  &.ScrollbarsCustom-TrackY {
    width: 10px;
    height: calc(100% - 10px);
    top: 5px;
    right: 2px;
  }
}
.ScrollbarsCustom-Thumb {
  &.ScrollbarsCustom-ThumbX,
  &.ScrollbarsCustom-ThumbY {
    cursor: pointer;
    border-radius: 4px;
  }
  &.ScrollbarsCustom-ThumbX {
    height: 100%;
    width: 0px;
  }
  &.ScrollbarsCustom-ThumbY {
    height: 100%;
    width: 8px;
  }
}