
.setup-organization {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding: 45px 25px 20px;

  form {
    h3 {
      color: #868f9f;
      margin-bottom: 2rem;
      font-weight: 600;
    }
  }
  .bp4-form-group {
    margin-bottom: 24px;

    .bp4-input-group {
      .bp4-input {
        height: 38px;
      }

    }
    .bp4-input,
    .form-group--select-list .bp4-button{
      font-size: 15px;
    }
  }

  label.bp4-label{
    color: #20242e;
  }

  .bp4-button:not([class*='bp4-intent-']):not(.bp4-minimal) {
    width: 100%;
    height: 38px;
    padding: 8px;
  }

  .bp4-text-muted {
    color: #000000;
  }

  .register-org-note {
    font-size: 13px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 1.75rem;
    color: #666;
  }

  .register-org-button {
    .bp4-button {
      background-color: #1c2448;
      height: 40px;
      font-size: 15px;
      width: 100%;

      &:disabled,
      &.bp4-loading{
        background-color: rgba(28, 36, 72, 0.5);
      }
    }
  }
}
