.details-menu {

  &--vertical {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .detail-item{
      flex-direction: column;
      flex-grow: 1;

      &__content{
        margin: 2px 0;
      }
    }
  }

  &--horizantal {
    display: flex;
    flex-direction: column;

    .detail-item{
      flex-direction: row;

      &:not(:first-of-type){
        margin-top: 12px;
      }

      &__label{
        padding-right: 10px;
      }

      &__content{
        width: 100%;
      }
    }
  }

  .detail-item {
    line-height: 1.3rem;
    display: flex;

    &__label {
      color: #727983;
      font-weight: 500;
    }

    &__content {
      text-transform: capitalize;
    }
  }

  + .details-menu{
    margin-top: 18px;
  }
}
