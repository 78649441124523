@import '../../_base.scss';

// Preferences topbar.
// -----------------------------
.preferences-topbar {
  border-bottom: 1px solid #d2dde2;
  min-height: 60px;
  flex: 60px 0 0;
  padding: 0 0 0 22px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;

  &__title {
    h2 {
      font-size: 22px;
      font-weight: 400;
      margin: 0;
      color: #48485b;
    }
  }

  &__user {
    display: flex;
    align-items: center;
    margin-right: 14px;

    .bp4-button:not([class*='bp4-intent-']):not(.bp4-minimal) {
      padding: 0;
      background-size: contain;
      background-color: #eed1f2;
      border-radius: 50%;
      height: 32px;
      width: 32px;

      .user-text {
        font-size: 12px;
        color: #fff;
      }
      &,
      &:hover,
      &:focus {
        background-color: #cb20e5;
        border: 0;
        box-shadow: none;
      }
    }
  }

  &__actions {
    margin-left: auto;
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px solid #e5e5e5;

    .bp4-button + .bp4-button {
      margin-left: 10px;
    }
  }
}
