

//  General page
//---------------------------------
.preferences-page__inside-content--general {

  .bp4-form-group {
    max-width: 650px;
    margin-bottom: 24px;

    .bp4-label {
      min-width: 190px;
      font-weight: 600;
      color: #38414e;
    }

    .bp4-form-content {
      width: 100%;
    }
  }
}
